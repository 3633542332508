import get from "lodash/get";
// import { ANS_CREATE, INPUT,ULR_BOSTADSITE,ULR_INFOPING } from "../store/tellus/types";
import { post_ip_function, post_ip } from "../api.js";
import { COOKIE_NAME_SESSION } from "@/constants.js";

// import moment from "moment";

export default {
  data() {
    return {
      dummy: null,
    };
  },
  methods: {
    get_settings_info(from, name, value, what) {
      var answer = "";
      var obj = this.get_object_settings("info");
      var store_name = this.get_store_name(obj);
      var info = this.get_store(store_name);

      var info_name = "";
      var info_value = "";
      var info_what = "";

      var names_lst = get(info, "names", []);


      names_lst.forEach((names) => {
        if (names.name == name) {
          info_name = names.info;
          if (value && value.length > 0 && names.values.length > 0) {
            names.values.forEach((values) => {
              if (values.value == value) {
                info_value = values.info;
                if (what && what.length > 0 && values.whats.length > 0) {
                  values.whats.forEach((whats) => {
                    {
                      if (whats.what == what) info_what = whats.info;
                    }
                  });
                }
              }
            });
          }
        }
      });

      if(from=='name')
        answer=info_name;
      else if(from=='value')
      answer=info_value;
      else if(from=='what')
      answer=info_what;


      return answer;
    },
    is_standard(from, name, value, what) {
      var answer = false;
      var obj = this.get_object_settings("settings");
      var store_name = this.get_store_name(obj);
      var info = this.get_store(store_name);

      var standard_name = false;
      var standard_value = false;
      var standard_what = false;

      var names_lst = get(info, "names", []);


      names_lst.forEach((names) => {
        if (names.name == name) {
          standard_name = true;
          if (value && value.length > 0 && names.values.length > 0) {
            names.values.forEach((values) => {
              if (values.value == value) {
                standard_value = true;
                if (what && what.length > 0 && values.whats.length > 0) {
                  values.whats.forEach((whats) => {
                    {
                      if (whats.what == what) standard_what = true;
                    }
                  });
                }
              }
            });
          }
        }
      });

      if(from=='name')
        answer=standard_name;
      else if(from=='value')
      answer=standard_value;
      else if(from=='what')
      answer=standard_what;


      return answer;
    },
    async set_standard(from, name, value, what) {
      var obj=this.get_object_settings('settings');
      obj.data.command='add'
      if(name && name.length>0)
        obj.data.name=name;
      if(value && value.length>0)
        obj.data.value=value;
      if(what && what.length>0)
        obj.data.what=what;

      if(from=='name')
      {
        obj.data.value=null;
        obj.data.what=null;
      }
      else 
      if(from=='value')
      {
        obj.data.what=null;
      }

      if(obj.data.name.length>0)
      {
        return await this.get_ip_post(obj,true).then((response =>
          {
            return  response;
          }));
      }

      
    },
    async del_standard(from, name, value, what) {

      if(from && name && name.length>0 && from.length>0 && (from=='name' || from=='value' || from=='what'))
      {
        
      var obj=this.get_object_settings('settings');

      var store_name=this.get_store_name(obj);
      var store_info=this.get_store(store_name);

      var del_arr= new Array();
      var del_item= new Object();
      var del_name=''
      var del_value=''
      var del_what=''

      //HITTAR OBJEKTET NAMNET name
      store_info.names.forEach(obj_name => {

        if(obj_name.name==name)
        {
          del_name=obj_name.name;
          obj_name.values.forEach(obj_value => {
            // DELETE ALL VALUES
            if(from=='name') 
            {
              del_value=obj_value.value;
              obj_value.whats.forEach(obj_what => {
                del_what=obj_what.what;

                del_item= new Object();
                del_item.name=del_name;
                del_item.value=del_value;
                del_item.what=del_what;
                del_arr.push(del_item);

              });
            }
            //DELETE ALL WHATS
            else if(from=='value')
            {
                if(obj_value.value==value)
                {
                  del_value=obj_value.value;
                  obj_value.whats.forEach(obj_what => {
                    del_what=obj_what.what;
    
                    del_item= new Object();
                    del_item.name=del_name;
                    del_item.value=del_value;
                    del_item.what=del_what;
                    del_arr.push(del_item);
    
                  });
                }
            }
            //DELETE ONE WHATS
            else if(from=='what')
            {
                if(obj_value.value==value)
                {
                  del_value=obj_value.value;
                  obj_value.whats.forEach(obj_what => {
                    if(obj_what.what==what)
                    {

                      del_what=obj_what.what;
    
                      del_item= new Object();
                      del_item.name=del_name;
                      del_item.value=del_value;
                      del_item.what=del_what;
                      del_arr.push(del_item);
                    }
    
                  });
                }
            }
            
          });

        }
        
      });

      
      //console.log('del_arr',del_arr)

      // DELET INFO
      obj=this.get_object_settings('info');
      obj.data.command='del'

      del_arr.forEach(del => {
          obj.data.name=del.name;
          obj.data.value=del.value;
          obj.data.what=del.what;
          this.get_ip_post(obj,false)
        
      });
      // DELETE SETTINGS
      obj=this.get_object_settings('settings');
      obj.data.command='del'

      del_arr.forEach(del => {
          obj.data.name=del.name;
          obj.data.value=del.value;
          obj.data.what=del.what;
          if(del_arr.indexOf(del)<del_arr.length-1)
            this.get_ip_post(obj,false)
        
      });
      // WAIT FOR RESPONSE OF LAST DELETE
      
      obj.data.name=del_arr[del_arr.length-1].name;
      obj.data.value=del_arr[del_arr.length-1].value;
      obj.data.what=del_arr[del_arr.length-1].what;


        return await this.get_ip_post(obj,true).then((response =>
          {
            return response;
          }));


      // if(obj.data.name.length>0)
      // {
      //   return await this.get_ip_post(obj,true).then((response =>
      //     {
      //       return response;
      //     }));
      // }

    }
    else
      return await null;
      
    },
    obj_clone(obj){
      return JSON.parse(JSON.stringify(obj));
    },
    get_object_Q(name) {
      var obj = new Object();
      obj.function = name;

      var data = new Object();
      var Q = new Object();
      data.Q = Q;
      obj.data = data;

      return obj;
    },
    get_object(name) {
      var obj = new Object();
      obj.function = name;

      var data = new Object();
      obj.data = data;

      return obj;
    },
    has_value(obj)
    {
      var answer=false;
      if(obj && obj!=null)
      {
        if(Array.isArray(obj) && obj.length>0)
          answer=true;
        else if((typeof obj === 'string' || obj instanceof String) && obj.length>0)
          answer=true;
        else
          answer=true;

      }

        // console.log('has_value',obj,answer)
      return answer;
    },
    get_what(item,settings_rows)
    {
        var answer=''
        var arr=settings_rows.filter(v => v.name==item.name && v.value==item.value && v.what.length>0);
        if(arr && arr.length>0)
        {
          if(arr.length==1)
            answer=arr[0].what;
          else
            answer=this.get_missing_name();
        }
        return answer;
    },
    name_exists(name,settings_rows)
    {
        var answer=false
        var arr=settings_rows.filter(v => v.name==name );
        if(arr && arr.length>0)
        {
          answer=true;
        }
        return answer;
    },
    get_missing_name()
    {
      return 'missing - '+Math.random().toString(36).substring(2, 7)
    },
    get_mandatory(str)
    {
      if(str=='mandatory')
        return this.get_missing_name();
      else
        return str;
    },
    get_property_names(obj)
    {
      var answer= new Array();
      if(obj)
      {
        var arr=Object.getOwnPropertyNames(obj);
        if(arr && arr.length>0)
        {
          arr.forEach(element => {
            if(element!='__ob__')
              answer.push(element);
            
          });
        }
      }
      return answer;
    },
    get_object_access(name) {
      var obj = new Object();
      obj.function = "access";

      var data = new Object();
      // har problem med att den här blir null så testar hämta session från cookies istället. /Anders
      //data.session = this.get_store('session');
      data.session = this.$cookies.get(COOKIE_NAME_SESSION);
      data.superadmin = "1";
      data.access_type = "attributes";
      data.name = name;
      obj.data = data;

      return obj;
    },
    get_object_settings(list) {
      var obj = new Object();
      obj.function = "bostadsite/settings";

      var data = new Object();
      data.list = list;
      data.command = "show"; // DEFAULT

      obj.data = data;

      return obj;
    },

    get_settings(name, value) {
      var answer = new Array();
      var item = new Object();
      var bostadsite_settings = this.get_store("bostadsite/settings");
      var names = get(bostadsite_settings, "names", []);
      names.forEach((name_item) => {
        // ALL NAMES - DEFAULT
        if (!name) {
          item = new Object();
          item.text = name_item.name;
          item.value = name_item.name;
          item.gen = 1;
          answer.push(item);
        } else if (name == name_item.name) {
          name_item.values.forEach((value_item) => {
            // ALL VALUES
            if (!value) {
              item = new Object();
              item.text = value_item.value;
              item.value = value_item.value;
              item.gen = 1;
              answer.push(item);
            } else if (value == value_item.value) {
              value_item.whats.forEach((whats_item) => {
                item = new Object();
                item.text = whats_item.what;
                item.value = whats_item.what;
                item.gen = 1;
                answer.push(item);
              });
            }
          });
        }
      });

      return answer;
    },
    get_store(what) {
      //console.log('get_store:', get(this.$store.state.tellus, what, null));
      return get(this.$store.state.tellus, what, null);
    },
    get_store_name(obj) {
      // NAME IN STORE
      var answer = "";
      if (obj.function == "access") {
        if (obj.data.name == "organization") {
          var org_key = get(obj.data, "org_key", "");
          if (org_key == "") answer = "company_list";
          else answer = "company";
        }
      } else if (obj.function == "bostadsite/settings") {
        if (obj.data.list == "info") answer = "bostadsite/info";
        else answer = obj.function;
      } else answer = obj.function;

      return answer;
    },
    async commit_obj(obj) {
      await this.$store.dispatch("tellus/commit_answer", obj);
    },
    async get_ip_post_function(obj, save) {
      return await post_ip_function(obj.function, obj.data).then((response) => {
        if (response && response != null) {
          // SAVE ANSWER TO STORE
          if (save) {
            var o = new Object();
            o.name = this.get_store_name(obj);
            o.data = response;
            this.commit_obj(o);
          }
          return response;
        } else return null;
      });
    },

    async get_ip_post(obj, save) {
      return await post_ip(obj.function, obj.data).then((response) => {
        if (response && response != null) {
          // SAVE ANSWER TO STORE
          if (save) {
            var o = new Object();
            o.name = this.get_store_name(obj);
            o.data = response;
            this.commit_obj(o);
          }
          return response;
        } else return null;
      });
    },
  },

  computed: {},
};
