// import get from "lodash/get";
export default {
//   customer_attributes(state) {
//     var only_org_items = get(state, "access[0].orgs[0].items", []);
//     var customers = get(state, "access[0].orgs[0].customers", []);
// console.log('getter')
//     var rows = new Array();

//     // ONLY ORG
//     if (only_org_items.length > 0) {
//       only_org_items.forEach((item) => {
//         var row = new Object();
//         row.where = "Alla";
//         row.customerId = "";
//         row.hidden = item.hidden;
//         row.name = item.name;
//         row.value = item.value;
//         row.what = item.what;
//         row.web_access = item.web_access;
//         rows.push(row);
//       });
//     }
    
//       // CUSTOMERS
//       if(customers.length>0)
//       {
//         customers.forEach(customer => {
//           if(customer.items.length>0)
//           {
//             customer.items.forEach(item => {
//               var row = new Object();
//               row.where = customer.name;
//               row.customerId = customer.customerId;
//               row.hidden = item.hidden;
//               row.name = item.name;
//               row.value = item.value;
//               row.what = item.what;
//               row.web_access = item.web_access;
//               rows.push(row);
              
//             });
//           }
          
//         });
//       }

//       return rows;
  // },
};
