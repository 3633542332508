<template>
  <v-app>
    <div>
      <router-view />
    </div>
  </v-app>
</template>

<script>
//import get from 'lodash/get';
import Mixin from './components/Mixin.js';

import { COOKIE_NAME_SESSION } from '@/constants.js';

export default {
  name: 'App',
  mixins: [Mixin],
  data() {
    return {
      urlHasSession: false,
      showUserNameDialog: false,
      inputText: '',
    };
  },
  components: {},
  watch: {},
  computed: {},
  created() {},

  destroyed() {},
  beforeMount() {
    // COLOR
    this.$vuetify.theme.themes.light.primary = '#3F383C';

    //dirigera om användaren till https om de kommer via http  (endast prod)
    var loc = window.location + '';
    if (loc.indexOf('http://') == 0 && process.env.NODE_ENV === 'production') {
      window.location.href = loc.replace('http://', 'https://');
      return;
    }

    // hur sessionen hanteras
    //1. kolla session i url, den gör override på allt annat. Verifiera och spara den om giltig, annars login
    //2. kolla session i cookie, verifiera och använd den.
    //3. ingen session hittats => login

    // 1. hämta s parametern ur URL (sessionen) och kolla om det är samma som i storen annars skicka till login
    const urlparams = new URLSearchParams(window.location.search);
    var session = urlparams.get('s');

    if (!session || session == null) {
      //console.log('No valid session in url');
    }

    // 2. cookie
    if (
      (!session || session == null) &&
      this.$cookies.isKey(COOKIE_NAME_SESSION)
    ) {
      session = this.$cookies.get(COOKIE_NAME_SESSION);
    }

    // validera session om vi hittat nån
    if (session && session != null) {
      var obj = this.get_object_Q('verify_token_v3');
      obj.data.Q.session = session;
      this.get_ip_post_function(obj, true).then((response) => {
        if (response.A.status == '1') {
          // SPARAR SESSIONEN I COOKIE
          this.$cookies.set(COOKIE_NAME_SESSION, session);
          this.urlHasSession = true;
          obj.name = 'session';
          obj.data = session;
          this.commit_obj(obj);
        } else {
          //console.log("No valid session in url or cookie");
          this.urlHasSession = false;
          this.$cookies.remove(COOKIE_NAME_SESSION);
        }
      });
    } else {
      // 3. ingen session hittad    => inloggning
      //console.log("No session found in url or cookie");
      this.urlHasSession = false;
      this.$cookies.remove(COOKIE_NAME_SESSION);
      if (window.location.pathname != '/') {
        this.$router.replace('/');
      }
    }
  },
};
</script>
<style lang="scss">
#app {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
</style>
