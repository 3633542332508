import {
  ATTRIBUTES_LIST,
  COMPANY,
  COMPANY_LIST,
  SESSION,
  SET_ANSWER,
  VERIFY_TOKEN_V3,
} from './types.js';

export default {
  [SET_ANSWER](state, obj) {
    if (obj.name && obj.name != null) {
      if(obj.data && obj.data!=null)
        state[obj.name] = obj.data;
        else
        state[obj.name]=null;
    }
    // else
    // {
    //   console.log('nolla')
    //   for (let f in state) {
    //     state[f]=null;
    //   }
    // }
  },

  [VERIFY_TOKEN_V3](state, payload) {
    state.verify_token_v3 = payload;
  },

  [SESSION](state, payload) {
    state.session = payload;
  },

  [COMPANY_LIST](state, payload) {
    state.company_list = payload;
  },

  [COMPANY](state, payload) {
    state.company = payload;
  },
  [ATTRIBUTES_LIST](state, payload) {
    state.attributes_list = payload;
  },
};
