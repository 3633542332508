export const SET_ANSWER = 'SET_ANSWER';

export const ULR_BOSTADSITE = 'https://bostad.site/';

export const ULR_INFOPING = 'https://infoping.net/s/?p=';

export const VERIFY_TOKEN_V3 = 'verify_token_v3';

export const SESSION = 'session';

export const COMPANY_LIST = 'company_list';

export const COMPANY = 'company';

export const ATTRIBUTES_LIST = 'attributes_list';
