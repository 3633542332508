import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    verify_token_v3: {},
    session: null,
    company_list: {},
    company: {},
    attributes_list: [],
  },

  actions,
  getters,
  mutations,
};
