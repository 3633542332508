<template>
  <v-app>
    <div class="loginBackground">
      <div>
        <v-row class="justify-center pa-15">
          <p class="adminHeader">Bostad.site admininstration</p>
        </v-row>

        <v-row class="justify-center">
          <v-card style="width: 400px">
            <v-card-title class="headline justify-center" primary-title
              >Inloggning</v-card-title
            >
            <v-card-text>
              <v-form v-model="form1Valid">
                <div style="padding-top: 20px">
                  <v-text-field
                    filled
                    v-model="phone_number"
                    label="Ange mobilnummer"
                    clearable
                    :disabled="showconfirm || loading"
                    @keydown="keypressedPhone"
                    autofocus
                  ></v-text-field>
                </div>
              </v-form>

              <div v-if="showconfirm">
                <label>Fyll i koden du fått på sms:</label>
                <br />
                <v-text-field
                  class="upperCaseInput"
                  filled
                  v-model="sms_code"
                  label="Valideringskod:"
                  placeholder="XX-1234"
                  :rules="[rules.required, rules.otp]"
                  clearable
                  autofocus
                  :disabled="loading"
                  @keydown="keypressedCode"
                ></v-text-field>
              </div>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn
                v-if="showconfirm"
                color="secondary"
                text
                @click="resetForm"
                >Avbryt</v-btn
              >

              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                :loading="loading"
                @click="confirmButtonClick"
                :disabled="!form1Valid || loading"
                >{{ confirmButtonLabel }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-row>
      </div>
    </div>
  </v-app>
</template>

<script>
// import rest2Api from '@/api/rest2Api.js';
import { COOKIE_NAME_SESSION } from '@/constants.js';
import Mixin from '@/components/Mixin.js';

export default {
  name: 'login-page',
  mixins: [Mixin],
  bodyClass: 'login-page',
  data() {
    return {
      phone_number: '',
      sms_code: '',
      showconfirm: false,
      confirmButtonLabel: 'Nästa',
      form1Valid: true,
      loading: false,
      rules: {
        required: (value) => !!value || 'Obligatoriskt.',
        phone_number: (value) => {
          const pattern = /^[+]*[0-9]{2,2}[1-9]{1,1}[0-9]{8,8}$/;
          return pattern.test(value) || 'Ogiltigt telefonnummer';
        },
        otp: (value) => {
          const pattern = /^[A-Za-z]{2,2}[-][0-9]{4,4}$/;
          return pattern.test(value) || 'Ogiltigt format på koden: XX-1234';
        },
      },
    };
  },
  mounted() {},
  watch: {
    sms_code() {
      if (this.sms_code && this.sms_code.length == 2) this.sms_code += '-';
    },
  },
  methods: {
    initLogin() {
      if (this.form1Valid) {
        var obj = this.get_object_Q('login_initiate');
        obj.data.Q.phone_number = this.phone_number;
        this.get_ip_post_function(obj, false).then((res) => {
          if (res.A.status == '1') this.getCode();
          else {
            obj = this.get_object('alertData');
            obj.data.show = true;
            obj.data.bodyText = res.error;
            this.commit_obj(obj);
          }
        });
      }
    },

    getCode() {
      //console.log('get code!: ', this.phone_number);
      this.confirmButtonLabel = 'Verifiera kod';

      var obj = this.get_object_Q('login_sms_send');
      obj.data.Q.phone_number = this.phone_number;

      this.get_ip_post_function(obj, false);
      this.showconfirm = true;
    },
    confirmCode() {
      //console.log("koden är: ", this.sms_code);
      //skicka till api och vänta på svar .then

      var obj = this.get_object_Q('login_sms_confirm');
      obj.data.Q.phone_number = this.phone_number;
      obj.data.Q.code = this.sms_code;

      this.get_ip_post_function(obj, false).then((res) => {
        if (res.A.status == '1') {
          this.$cookies.set(COOKIE_NAME_SESSION, res.A.session);
          this.$store.dispatch('tellus/set_session', res.A.session);
          this.$router.replace('admin');
          //var path = process.env.NODE_ENV === 'production' ? '/' : '/';
          //window.location.href = path + '?s=' + res.A.session;
        } else {
          var errorMsg = res.A.error;

          if (errorMsg == 'login not initiated') {
            errorMsg = 'Fel kod';
          }
          if (errorMsg == 'login timed out') {
            errorMsg = 'Inloggningen tog för lång tid';
          }
          obj = this.get_object('alertData');
          obj.data.show = true;
          obj.data.bodyText = 'Något gick fel: ' + errorMsg;
          this.commit_obj(obj).then(() => {
            this.resetForm();
          });
        }
      });
    },
    keypressedPhone(keyEvt) {
      if (keyEvt.key == 'Enter') this.confirmButtonClick();
    },
    keypressedCode(keyEvt) {
      if (keyEvt.key == 'Enter') this.confirmButtonClick();
      if (keyEvt.key == '-') {
        keyEvt.preventDefault();
      }
    },
    confirmButtonClick() {
      if (this.showconfirm == false) {
        this.showconfirm = true;

        this.initLogin();
      } else {
        if (this.sms_code != '') {
          this.loading = true;
          this.confirmCode();
        }
      }
    },
    resetForm() {
      this.showconfirm = false;
      this.phone_number = '';
      this.sms_code = '';
      this.loading = false;
      this.confirmButtonLabel = 'Nästa';
    },

    isMobile() {
      if (
        /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  components: {},
};
</script>

<style>
body,
html {
  height: 100%;
  width: 100%;
}

.upperCaseInput input {
  text-transform: uppercase;
}

.adminHeader {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 50px;
  color: white;
}

.loginBackground {
  background-image: url('~@/assets/pexels-jorge-jesus-614117.jpg');
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.headlineback {
  background-color: #47cb87;
  color: white;
}
</style>
