import Vue from 'vue';
import Vuex from 'vuex';
import tellus from './tellus';

Vue.use(Vuex)

export default () =>
  new Vuex.Store({
    modules: { tellus},
    strict: process.env.NODE_ENV !== 'production'
  });
