import axios from 'axios';
//import get from "lodash/get";

const URL_IP_FUNCTION = 'https://infoping.net/REST2/ask/Q/json/?function=';
const URL_IP = 'https://infoping.net/REST2/ask/Q/';

//****** GENERAL FUNCTION POST TO BACKEND
export async function post_ip_function(ip_function, obj) {
  let post_data = JSON.stringify(obj);
  const url = URL_IP_FUNCTION + ip_function;
 
  return await axios
    .post(url, post_data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
     
      return response.data;
    })
    .catch((e) => {
      return { status: 0, error: e.message };
    });
}
export async function post_ip(ip_function, obj) {
  let post_data = JSON.stringify(obj);
  
  const url = URL_IP + ip_function;
 return await axios
    .post(url, post_data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      return { status: 0, error: e.message };
    });
}
