<template>
  <v-app style="background-color: #dbdbdb">
    <div class="align-self-center pa-2" style="font-size: 23px">
      Administrera attribut
    </div>
    <div>
      <v-card class="ml-10 mr-10 mb-5 mt-5">
        <v-row>
          <v-col cols="1"></v-col>
          <!-- CUSTOMERS -->
          <v-col cols="4">
            <label>KUNDER</label>
            <br />
            <br />
            <v-select
              v-if="show_companies"
              outlined
              dense
              class="overflowOverride"
              :items="customers"
              item-text="name"
              item-value="org_key"
              label="Välj kund:"
              v-on:change="clear_attribute"
              v-model="selectedCustomer"
            ></v-select>
          </v-col>

          <!-- FILTER -->
          <v-col cols="4">
            <label>FILTER</label>
            <br />
            <br />
            <v-select
              v-if="show_companies"
              outlined
              dense
              class="overflowOverride"
              :items="offices"
              item-text="text"
              item-value="value"
              label="Välj kontor:"
              v-on:change="clear_attribute"
              v-model="selectedFilter"
            ></v-select>
          </v-col>

          <!-- LOGGA UT -->
          <v-col cols="2" class="align-self-center mt-5">
            <v-btn @click="logout()">Logga ut</v-btn>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
      </v-card>

      <!-- INSTÄLLNINGAR -->
        <v-card class="ml-10 mr-10 mb-5 mt-5">
          <!-- <v-row class="orange pd-zero">
          <v-col class="orange pd-zero"> -->
          <v-data-table
            :headers="headers"
            :items="row_lst"
            item-key="items.id"
            class="elevation-1 row-height-50 overflow-y-hidden"
            :search="search"
            :item-class="row_class"
            @click:row="row_click"
          >
            <template v-slot:top >
              <div class="mt-5 ml-5" >
                <v-row v-if="show_customer"  style="padding:0px; margin:0px; max-width:100%">
                  <v-col cols="2">
                    <label>Inställningar</label>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="search"
                      label="Sök"
                      class="mx-4"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-btn
                      :disabled="!btn_deselect_show"
                      @click="btn_deselect()"
                    >
                      Avmarkera rad
                    </v-btn>
                  </v-col>
                  <v-col cols="4" class="justify-end">
                    <v-checkbox
                      v-model="chk_box_filter_name"
                      label="Filtrera på valt namn"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row v-else style="padding:0px; margin:0px; max-width:100%">
                  <v-col cols="12" style="padding:0px; margin:0px">
                    <label style="color: red">
                      Välj kund ovan för att se data nedan
                    </label>
                  </v-col>
                </v-row>
              </div>
            </template>
          </v-data-table>
          <!-- </v-col>
        </v-row> -->
        </v-card>

        <!-- LÄGG TILL -->
        <template>
          <v-row class="ml-7 mr-7 mt-2 mb-15">
            <v-col>
              <v-card class="pa-5">
                <!-- <v-toolbar flat>
                <v-toolbar-title>{{ rubrik }}</v-toolbar-title>
              </v-toolbar> -->

                <v-row>
                  <h3>{{ rubrik }}</h3>
                  <v-spacer></v-spacer>
                  <v-select
                      outlined
                      dense
                      class="overflowOverride"
                      label="Kategori"
                      :items="category_lst"
                      item-text="text"
                      item-value="value"
                      v-model="selectedCategory"
                      v-on:change="clear_value_what()"
                      >
                    </v-select>
                  <v-spacer></v-spacer>
                  <v-checkbox
                    v-model="checkbox"
                    label="Endast standardattribut"
                  ></v-checkbox>
                </v-row>

                <v-row>
                  <v-col>
                    <v-select
                      outlined
                      dense
                      class="overflowOverride"
                      :items="hidden_lst"
                      item-text="text"
                      item-value="value"
                      label="Hidden"
                      v-model="selectedHidden"
                    ></v-select>
                  </v-col>
                  <v-col>
                    <v-select
                      outlined
                      dense
                      class="overflowOverride"
                      :items="who_lst"
                      item-text="text"
                      item-value="value"
                      label="Vem"
                      v-model="selectedWho"
                    ></v-select>
                  </v-col>
                  <v-col>
                    <v-select
                      outlined
                      dense
                      class="overflowOverride"
                      :items="web_access_lst"
                      item-text="text"
                      item-value="value"
                      label="Web access"
                      v-model="selectedWebAccess"
                    ></v-select>
                  </v-col>
                </v-row>
                <!-- NAME -->
                <v-row>
                  <v-col>
                    <v-select
                      outlined
                      dense
                      class="overflowOverride"
                      label="Name"
                      :items="name_lst"
                      item-text="text"
                      item-value="value"
                      v-model="selectedName"
                      v-on:change="changeDrop('name')"
                    >
                      <template v-slot:item="{ item }">
                        <span :style="get_item_style(item)">{{
                          item.text
                        }}</span>
                      </template>
                      <template v-slot:prepend-item>
                        <v-list-item>
                          <span style="color: red">RED = STANDARD</span>
                        </v-list-item>
                        <v-list-item>
                          <span style="color: black">BLACK = CUSTOM</span>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>

                  <v-col>
                    <v-text-field
                      label="Name"
                      v-model="selectedName"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!-- VALUE -->
                <v-row>
                  <v-col>
                    <v-select
                      outlined
                      dense
                      class="overflowOverride"
                      :items="value_lst"
                      item-text="text"
                      item-value="value"
                      label="Value"
                      v-model="selectedValue"
                    >
                      <template v-slot:item="{ item }">
                        <span :style="get_item_style(item)">{{
                          item.text
                        }}</span>
                      </template>
                      <template v-slot:prepend-item>
                        <v-list-item>
                          <span style="color: red">RED = STANDARD</span>
                        </v-list-item>
                        <v-list-item>
                          <span style="color: black">BLACK = CUSTOM</span>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col>
                    <v-text-field
                      label="Value"
                      v-model="selectedValue"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!-- WHAT -->
                <v-row>
                  <v-col>
                    <v-select
                      outlined
                      dense
                      class="overflowOverride"
                      :items="what_lst"
                      item-text="text"
                      item-value="value"
                      label="What"
                      v-model="selectedWhat"
                    >
                      <template v-slot:item="{ item }">
                        <span :style="get_item_style(item)">{{
                          item.text
                        }}</span>
                      </template>
                      <template v-slot:prepend-item>
                        <v-list-item>
                          <span style="color: red">RED = STANDARD</span>
                        </v-list-item>
                        <v-list-item>
                          <span style="color: black">BLACK = CUSTOM</span>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-textarea
                      label="What"
                      auto-grow
                      rows="1"
                      v-model="selectedWhat"
                    ></v-textarea>
                  </v-col>
                </v-row>

                <v-btn
                  :disabled="!btn_add_show"
                  @click="btn_add_attributes()"
                  class="ma-5"
                  >Lägg till</v-btn
                >
                <v-btn
                  :disabled="!btn_upd_show"
                  @click="btn_upd_attributes()"
                  class="ma-5"
                  >Uppdatera</v-btn
                >
                <v-btn
                  :disabled="!btn_del_show"
                  @click="btn_del_attributes()"
                  class="ma-5"
                  >Ta bort</v-btn
                >
                <v-btn
                  :disabled="!btn_clear_show"
                  @click="clear_attribute()"
                  class="ma-5"
                  >RENSA FORMULÄR</v-btn
                >
                <!-- TESTKNAPP -->
                <v-btn
                  @click="get_settings_row_category()"
                  class="ma-5"
                  >test</v-btn
                >
              </v-card>
            </v-col>
          </v-row>
        </template>
      <v-card v-if="show_info" class="ml-10 mr-10 mb-5 mt-5">
        <v-card-title>Information</v-card-title>
        <!-- NAME INFO -->
        <v-card class="ml-10 mr-10 mb-5 mt-5">
          <v-textarea
            v-model="name_info"
            :label="get_label(this.selectedName)"
          ></v-textarea>
          <v-row>
            <v-col cols="8">
              <v-btn @click="btn_upd_info('name')"
                >UPPDATERA BESKRIVNINGEN AV {{ this.selectedName }}</v-btn
              >
            </v-col>
            <v-col cols="4">
              <v-btn
                v-if="is_standard_attribute('name')"
                @click="del_standard_attribute('name')"
                >TA BORT SOM STANDARD</v-btn
              >
              <v-btn v-else @click="set_standard_attribute('name')"
                >SÄTT SOM STANDARD</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
        <!-- VALUE INFO -->
        <v-card v-if="show_value" class="ml-10 mr-10 mb-5 mt-5">
          <v-textarea
            v-model="value_info"
            :label="get_label(this.selectedValue)"
          ></v-textarea>
          <v-row>
            <v-col cols="8">
              <v-btn @click="btn_upd_info('value')"
                >UPPDATERA BESKRIVNINGEN AV {{ this.selectedValue }}</v-btn
              >
            </v-col>
            <v-col cols="4">
              <v-btn
                v-if="is_standard_attribute('value')"
                @click="del_standard_attribute('value')"
                >TA BORT SOM STANDARD</v-btn
              >
              <v-btn v-else @click="set_standard_attribute('value')"
                >SÄTT SOM STANDARD</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
        <!-- WHAT INFO -->
        <v-card v-if="show_what" class="ml-10 mr-10 mb-5 mt-5">
          <v-textarea
            v-model="what_info"
            :label="get_label(this.selectedWhat)"
          ></v-textarea>
          <v-row>
            <v-col cols="8">
              <v-btn @click="btn_upd_info('what')"
                >UPPDATERA BESKRIVNINGEN AV {{ this.selectedWhat }}</v-btn
              >
            </v-col>
            <v-col cols="4">
              <v-btn
                v-if="is_standard_attribute('what')"
                @click="del_standard_attribute('what')"
                >TA BORT SOM STANDARD</v-btn
              >
              <v-btn v-else @click="set_standard_attribute('what')"
                >SÄTT SOM STANDARD</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </div>
  </v-app>
</template>

<script>
import { COOKIE_NAME_SESSION } from "@/constants.js";
import get from "lodash/get";
import Mixin from "@/components/Mixin.js";
// import { mapGetters } from 'vuex';

export default {
  name: "AdminPage",
  mixins: [Mixin],
  data() {
    return {
      rubrik: "Attribut",
      btn_add_show: false,
      btn_upd_show: false,
      btn_del_show: false,
      btn_clear_show: false,
      btn_deselect_show: false,
      selected_item: null,
      dialog: false,
      show_companies: false,
      show_customer: false,
      selectedCustomer: "",
      selectedFilter: "no_filter",
      selectedName: "",
      selectedValue: "",
      selectedWhat: "",
      selectedWho: "",
      selectedHidden: "0",
      selectedWebAccess: "0",
      selectedCategory: "utom",
      search: "",
      offices: [],
      rows: [],
      all_rows: [],
      settings_rows: [],
      who_lst: [],
      checkbox: false,
      chk_box_filter_name: false,
      show_info: false,
      show_value: false,
      show_what: false,
      name_info: "",
      value_info: "",
      what_info: "",
      trigger: 0,
      dummy: "",
      catergory_selecteded:false,
      selected_category:null,
      data_filter:[],
    };
  },
  mounted() {},
  components: {},

  watch: {
    selectedCustomer(newVal) {
      this.clear_attribute();
      this.selectedFilter = "no_filter";
      this.get_customer(newVal, this.selectedFilter);
    },
    selectedFilter(newVal) {
      this.get_customer_attributes(newVal);
      if (newVal == "no_filter" || newVal == "") this.selectedWho = "";
      else this.selectedWho = newVal;
    },
    // BTN SHOW

    selectedHidden() {
      this.btn_show('watch_selected_hidden');
    },
    selectedWho() {
      this.btn_show('watch_selected_who');
    },
    selectedName() {
      this.show_information();
      this.get_customer_attributes(this.selectedFilter);
      this.btn_show('watch_selected_name');
    },
    selectedValue() {
      this.show_information();
      this.btn_show('watch_selected_value');
    },
    selectedWhat() {
      this.show_information();
      this.btn_show('watch_selected_what');
    },
    selectedWebAccess() {
      this.btn_show('watch_selected_webaccess');
    },
    
    selectedCategory() {
      // if(newVal!='alla')
      // this.set_category(newVal)
    },
    selected_item(newVal) {
      if (newVal == null) this.btn_deselect_show = false;
    },
    chk_box_filter_name() {
      this.get_customer_attributes(this.selectedFilter);
    },
  },
  created() {},

  destroyed() {},
  beforeMount() {
    // GET ALL CUSTOMERS
    var obj = this.get_object_access("organization");
    this.get_ip_post(obj, true).then((response) => {
      if (response && response != null) {
        this.customers = get(response, "access[0].orgs", []);
        this.show_companies = true;
      }
    });


    //this.refresh_settings();
      obj = this.get_object_settings("settings");
        this.get_ip_post(obj, true).then((response) => {
          if(response!=null)
          {

            this.settings_rows=this.get_settings_rows()
          
          }
        });
        // GET DROPLIST INFO
        obj = this.get_object_settings("info");
        this.get_ip_post(obj, true).then((response) => {
          this.dummy = response;
          this.trigger=this.trigger+1;
        });

  },
  computed: {
    // ...mapGetters({ rows: 'tellus/customer_attributes' }),
    headers() {
      return [
        {
          text: "Hidden",
          sortable: false,
          value: "hidden",
          width: "12%",
          align: "center",
        },
        { text: "Kontor", value: "who", width: "12%" },
        { text: "Name", value: "name", width: "12%" },
        { text: "Value", value: "value", width: "40%" },
        { text: "What", value: "what", width: "12%" },

        {
          text: "Web access",
          value: "web_access",
          width: "12%",
          align: "center",
        },
      ];
    },
    hidden_lst() {
      return [
        { text: "Yes", value: "1" },
        { text: "No", value: "0" },
      ];
    },
    category_lst() {
      // return [
      //   { text: "alla", value: "alla" },
      //   { text: "schedule", value: "schedule" },
      //   { text: "reg_sms", value: "reg_sms" },
      // ];
      var unic_names= this.settings_rows.filter((v,idx,self)=>
      self.findIndex(v2 => v.name===v2.name)===idx);
      
      var unic_values= this.settings_rows.filter((v,idx,self)=>
      self.findIndex(v2 => v.value===v2.value)===idx);

      var answer= new Array();
      var item= new Object();
      item.text="alla";
      item.value=item.text;
      answer.push(item);
      unic_names.forEach(name => {
        var exists=false;
        unic_values.forEach(value => {
          if(value.value==name.name)
            exists=true;
          
        });
        if(!exists)
        {
          item= new Object();
          item.text=name.name;
          item.value=item.text;
          answer.push(item);
        }
        
      });

      return answer;
    },
    web_access_lst() {
      return [
        { text: "0", value: "0" },
        { text: "1", value: "1" },
        { text: "2", value: "2" },
      ];
    },
    name_lst() {
      var answer = new Array();
      if (this.trigger >= 0) {
        if(this.catergory_selecteded)
        {
            
            var item = new Object();
            item.text = this.selectedCategory;
            item.value = this.selectedCategory;
            item.gen = 1;
            if (!answer.includes(item.text)) answer.push(item);
        }
        else
        {

          answer = this.get_settings();
          if (this.checkbox) {
            if (answer.length > 0) {
              answer.sort((a, b) => a.value.localeCompare(b.value));
            }
          }
          else
          {

          this.all_rows.forEach((row) => {
            var item = new Object();
            item.text = row.name;
            item.value = row.name;
            item.gen = 0;
            if (!answer.includes(item.text)) answer.push(item);
          });

          }
        }

        // SORT
        if (answer.length > 0) {
          answer.sort((a, b) => a.value.localeCompare(b.value));
        }

        
      }
      return answer;
    },
    value_lst() {
      var answer = new Array();
      if (this.trigger >= 0) {
        
        if(this.catergory_selecteded)
        {
          var obj=this.selected_category[this.selectedCategory];
          if(!this.has_value(obj))
            return answer;

            var arr=this.get_property_names(obj);
            arr.forEach(element => {
                if(element!='__ob__')
                {
                item = new Object();
                item.text = element;
                item.value = element;
                item.gen = 1;
                if (!answer.includes(item)) answer.push(item);

                }
              
            });
        }
        else
        {
            var item = new Object();
            item.text = "- tomt -";
            item.value = "";

            if (this.has_value(this.selectedName)) {
              
              answer = this.get_settings(this.selectedName);
              if (this.checkbox) {
                if (answer.length > 0) {
                  answer.sort((a, b) => a.value.localeCompare(b.value));
                }
                return answer;
              }
            }

            answer.push(item);
            if (this.has_value(this.selectedName)) {
              var filter = this.all_rows.filter((item) => {
                return item.name.match(this.selectedName);
              });

              filter.forEach((row) => {
                item = new Object();
                item.text = row.value;
                item.value = row.value;
                if (!answer.includes(item)) answer.push(item);
              });
            }

            // SORT
            if (answer.length > 0) {
              answer.sort((a, b) => a.value.localeCompare(b.value));
            }
        }
      
      }
      return answer;
    },
    what_lst() {
      var answer = new Array();
      if (this.trigger >= 0) {
      var item = new Object();
      item.text = "- tomt -";
      item.value = "";

      if (this.has_value(this.selectedName) && this.has_value(this.selectedValue)) {
        answer = this.get_settings(this.selectedName, this.selectedValue);
        if (this.checkbox) {
          if (answer.length > 0) {
            answer.sort((a, b) => a.value.localeCompare(b.value));
          }
          return answer;
        }
      }

      answer.push(item);
      if (this.has_value(this.selectedName)) {
        var filter = this.all_rows.filter((item) => {
          return item.name.match(this.selectedName);
        });

        filter.forEach((row) => {
          item = new Object();
          item.text = row.what;
          item.value = row.what;
          if (!answer.includes(item)) answer.push(item);
        });
      }

      // SORT
      if (answer.length > 0) {
        answer.sort((a, b) => a.value.localeCompare(b.value));
      }
      }
      return answer;
    },
    row_lst() {
      if(this.data_filter.length>0)
      {
        var answer= new Array();
        this.rows.forEach(element => {
               if(this.data_filter.indexOf(element.name)>=0)
                answer.push(element);
            });
            return answer;
      }
      return this.rows;
    },
  },
  methods: {
    get_settings_row_category(){
        //LÄGG TILL
        var answer = new Array();
        var arr_lvl1= null;
        var arr_lvl2= null;
        var arr_lvl3= null;
        var arr_lvl4= null;



        var obj1=this.selected_category[this.selectedCategory] ;
        arr_lvl1=this.get_property_names(obj1);

        if(arr_lvl1.length>0)
      {
          arr_lvl1.forEach(lvl1 => {
            if(lvl1==this.selectedValue || this.has_value(this.selectedValue)==false)
            {
              // LEVEL 1
              var item = new Object();
              item.name=this.selectedCategory;
              item.value=this.get_mandatory(lvl1);
              item.what=this.get_what(item,this.settings_rows)
              answer.push(item);
              // LEVEL 2
              var obj2=obj1[lvl1];
              arr_lvl2=this.get_property_names(obj2);
              if(arr_lvl2.length>0)
                {
                  arr_lvl2.forEach(lvl2 => {
                    
                  item = new Object();
                  item.name=lvl1;
                  item.value=this.get_mandatory(lvl2);
                  item.what=this.get_what(item,this.settings_rows)
                  answer.push(item);
                  // LEVEL 3
                  var obj3=obj2[lvl2];
                  arr_lvl3=this.get_property_names(obj3);
                  if(arr_lvl3.length>0)
                  {
                    arr_lvl3.forEach(lvl3 => {
                      
                    item = new Object();
                    item.name=lvl2;
                    item.value=this.get_mandatory(lvl3);
                    item.what=this.get_what(item,this.settings_rows)
                    answer.push(item);
                     // LEVEL 4
                    var obj4=obj3[lvl3];
                    arr_lvl4=this.get_property_names(obj4);
                      if(arr_lvl4.length>0)
                        {
                          arr_lvl4.forEach(lvl4 => {
                            
                          item = new Object();
                          item.name=lvl3;
                          item.value=this.get_mandatory(lvl4);
                          item.what=this.get_what(item,this.settings_rows)
                          answer.push(item);
                          });
                        }
                        else if(this.name_exists(lvl3,this.settings_rows))
                        {
                          item = new Object();
                          item.name=lvl3;
                          item.value=this.get_missing_name();
                          
                          item.what=this.get_what(item,this.settings_rows)
                          answer.push(item);
                        }
                    });
                    
                    
                  }
                  //LEVEL 3
                        else if(this.name_exists(lvl2,this.settings_rows))
                        {
                          item = new Object();
                          item.name=lvl2;
                          item.value=this.get_missing_name();
                          
                          item.what=this.get_what(item,this.settings_rows)
                          answer.push(item);
                        }
                  });
                   
                }
                  //LEVEL 2
                        else if(this.name_exists(lvl1,this.settings_rows))
                        {
                          item = new Object();
                          item.name=lvl1;
                          item.value=this.get_missing_name();
                          
                          item.what=this.get_what(item,this.settings_rows)
                          answer.push(item);
                        }

            }
          });
      }
      return answer

    },
    clear_value_what(){
      this.selectedValue=null;
      this.selectedWhat=null;
      this.selectedName=this.selectedCategory;
     // this.set_category(this.selectedCategory);
      this.refresh_settings()
    },
    set_category(category){
      var lvl1= new Object();

      

      var lvl1_values=this.settings_rows.filter(v => v.name==category)
      
      if(this.has_value(lvl1_values))
      {
      // LEVEL 1
      lvl1[category]= new Object();
      var unic_lvl1_values= lvl1_values.filter((v,idx,self)=>
      self.findIndex(v2 => v.value===v2.value)===idx);
      unic_lvl1_values.forEach( lvl1_value=> {
        if(lvl1_value.value && lvl1_value.value.length>0)
        {
          lvl1[category][lvl1_value.value]=new Object()
          var lvl2_values=this.settings_rows.filter(v => v.name==lvl1_value.value)
          // LEVEL 2
          if(lvl2_values && lvl2_values.length>0)
          {
            var unic_lvl2_values= lvl2_values.filter((v,idx,self)=>
            self.findIndex(v2 => v.value===v2.value)===idx);

            unic_lvl2_values.forEach(lvl2_value => {
              if(lvl2_value.value && lvl2_value.value.length>0)
              {
                lvl1[category][lvl1_value.value][lvl2_value.value]=new Object()
                var lvl3_values=this.settings_rows.filter(v => v.name==lvl2_value.value)
                // LEVEL 3
                if(lvl3_values && lvl3_values.length>0)
                {
                  var unic_lvl3_values= lvl3_values.filter((v,idx,self)=>
                  self.findIndex(v2 => v.value===v2.value)===idx);

                  unic_lvl3_values.forEach(lvl3_value => {
                    if(lvl3_value.value && lvl3_value.value.length>0 )
                    {
                      lvl1[category][lvl1_value.value][lvl2_value.value][lvl3_value.value]=new Object()
                      var lvl4_values=this.settings_rows.filter(v => v.name==lvl3_value.value)
                      // LEVEL 4
                      if(lvl4_values && lvl4_values.length>0)
                      {
                        var unic_lvl4_values= lvl4_values.filter((v,idx,self)=>
                        self.findIndex(v2 => v.value===v2.value)===idx);

                        unic_lvl4_values.forEach(lvl4_value => {
                          if(lvl4_value.value && lvl4_value.value.length>0 )
                          {
                            lvl1[category][lvl1_value.value][lvl2_value.value][lvl3_value.value][lvl4_value.value]=new Object()
                          }
                          
                        });
                      }
                    }
                    
                  });
                }
              }
            });
          }
        }
      });
      }
      
        var obj= new Object();
        obj.name='category';
      // if(lvl1[category] && Object.getOwnPropertyNames(lvl1[category]).length>0)
      if(this.has_value(lvl1[category]))
      {
        obj.data=lvl1;
        this.selected_category=lvl1;
        this.catergory_selecteded=true;
        this.selectedName=category;

        // FILTER ROWS
        this.data_filter=new Array();
        var rows= this.get_settings_row_category();
        
        var unic_names= rows.filter((v,idx,self)=>
          self.findIndex(v2 => v.name===v2.name)===idx);
        
        unic_names.forEach(element => {
          this.data_filter.push(element.name);
        });


          // if(this.has_value(this.rows))
          // {
          //   this.rows.forEach(element => {
          //      if(this.data_filter.indexOf(element.name)>=0)
          //       console.log('row',element.name,this.data_filter.indexOf(element.name));
          //   });
          // }
          


      }
        else
        {

        this.catergory_selecteded=false;
        this.selected_category=null;
        obj.data=null;
        this.selectedName=null;
        }
      // console.log('comit_obj',obj)
      this.commit_obj(obj);
      this.trigger=this.trigger+1;
      
      // var unic_names=dummy.filter((v,idx,self)=>
      // self.findIndex(v2 => v.name===v2.name)===idx);


        //console.log(lvl1);
        // if(lvl1[category])
        // console.log(Object.getOwnPropertyNames(lvl1[category]),lvl1,'hej');

        // dummy.forEach(element => {
        //   console.log(Object.getOwnPropertyNames(element)[0])
        // });

    },
    refresh_settings()
    {
          // GET ALL SETTINGS ROWS
          this.settings_rows=this.get_settings_rows();
        // GET DROPLIST SETTINGS - STANDARD
        var obj = this.get_object_settings("settings");
        this.get_ip_post(obj, true).then((response) => {
          if(response!=null)
          {

          if(this.has_value(this.selectedCategory))
          {
              this.set_category(this.selectedCategory);
              // if(!this.has_value(this.settings_rows))
              // {
              //   this.settings_rows=this.get_settings_rows();
              // }
          }
          }
        });
        // GET DROPLIST INFO
        obj = this.get_object_settings("info");
        this.get_ip_post(obj, true).then((response) => {
          this.dummy = response;
          this.trigger=this.trigger+1;
        });
    },
    get_settings_rows(){
      //console.log('get_settings_rows')
      var answer= new Array();
      var item= new Object();
      var obj=this.get_object_settings('settings');
      var obj_name=this.get_store_name(obj);
      var obj_settings=this.get_store(obj_name);
      //console.log('get_settings_rows2',obj_settings,this.has_value(obj_settings))
      // if(!this.has_value(obj_settings))
      // return answer;
      
      //console.log('get_settings_rows',3)
      obj_settings.names.forEach(name => {
        var item_name=name.name;
        if(name.values && name.values.length>0)
        {
          name.values.forEach(value => {
            var item_value=value.value;
            if(value.whats && value.whats.length>0)
            {
              value.whats.forEach(what => {
                
              item= new Object();
              item.name=item_name;
              item.value=item_value;
              item.what=what.what;
              answer.push(item);
                
              });
            }
            else
            {
              item= new Object();
              item.name=item_name;
              item.value=item_value;
              item.what='';
              answer.push(item);
            }
            
          });
        }
        else
        {
          item= new Object();
          item.name=item_name;
          item.value='';
          item.what='';
          answer.push(item);
        }
        
      });
      return answer;
      
    },
    changeDrop(what){
      if(what=='name')
        {
        this.selectedValue = "";
        this.selectedWhat = "";
        }
    },
    is_standard_attribute(text) {
      return this.is_standard(
        text,
        this.selectedName,
        this.selectedValue,
        this.selectedWhat
      );
    },
    set_standard_attribute(text) {
      this.set_standard(
        text,
        this.selectedName,
        this.selectedValue,
        this.selectedWhat
      ).then((response) => {
        if (response != null) {
          this.refresh_settings();
          // this.setting_rows=this.get_settings_rows();
          // this.trigger = this.trigger + 1;
        }
      });
    },
    del_standard_attribute(text) {
      this.del_standard(
        text,
        this.selectedName,
        this.selectedValue,
        this.selectedWhat
      ).then((response) => {
        if (response != null) {
          this.refresh_settings();
          // this.setting_rows=this.get_settings_rows();
          // this.trigger = this.trigger + 1;
        }
      });
    },
    get_label(text) {
      return "beskrivning av " + text;
    },
    show_information() {
      var show_info = false;
      var show_value = false;
      var show_what = false;
      if (this.selectedName && this.selectedName.length > 0) {
        this.name_info = this.get_settings_info(
          "name",
          this.selectedName,
          this.selectedValue,
          this.selectedWhat
        );
        show_info = true;
        if (this.selectedValue && this.selectedValue.length > 0) {
          this.value_info = this.get_settings_info(
            "value",
            this.selectedName,
            this.selectedValue,
            this.selectedWhat
          );
          show_value = true;
          if (this.selectedWhat && this.selectedWhat.length > 0) {
            this.what_info = this.get_settings_info(
              "what",
              this.selectedName,
              this.selectedValue,
              this.selectedWhat
            );
            show_what = true;
          }
        }
      }
      this.show_info = show_info;
      this.show_value = show_value;
      this.show_what = show_what;
    },

    btn_upd_info(what) {
      var obj = this.get_object_settings("info");
      obj.data.command = "add";
      obj.data.name = this.selectedName;
      if (what == "name") obj.data.info = this.name_info;
      else if (what == "value" && this.selectedValue.length > 0) {
        obj.data.value = this.selectedValue;
        obj.data.info = this.value_info;
      } else if (
        what == "what" &&
        this.selectedValue.length > 0 &&
        this.selectedWhat.length > 0
      ) {
        obj.data.value = this.selectedValue;
        obj.data.what = this.selectedWhat;
        obj.data.info = this.what_info;
      }

      if (obj.data.info.length == 0) obj.data.command = "del";

      this.get_ip_post(obj, true);
    },
    btn_del_info(what) {
      var obj = this.get_object_settings("info");
      obj.data.command = "add";
      obj.data.name = this.selectedName;
      if (what == "name") obj.data.info = this.name_info;
      this.get_ip_post(obj, true);
    },
    get_item_style(item) {
      if (item && item.gen && item.gen == 1) return "color:red";
      else return "color:black";
    },
    logout() {
      this.$cookies.remove(COOKIE_NAME_SESSION);
      this.$store.dispatch("tellus/reset_state_on_logout");
      this.$router.push("/");
    },
    con_log(val) {
      console.log("con_log", val);
    },
    row_class(item) {
      var id = get(this.selected_item, "id", null);
      if (id != null && item && item != null && item.id == id) return "orange";
    },
    clear_attribute() {
      (this.selectedName = ""),
        (this.selectedValue = ""),
        (this.selectedWhat = ""),
        (this.selectedWho = ""),
        (this.selectedHidden = "0"),
        (this.selectedWebAccess = "0"),
        (this.selected_item = null);
    },
    row_click(item) {
      this.selectedHidden = item.hidden;
      this.selectedWho = item.customerId;
      this.selectedName = item.name;
      this.selectedValue = item.value;
      this.selectedWhat = item.what;
      this.selectedWebAccess = item.web_access;
      this.selected_item = item;
      this.btn_deselect_show = true;


      this.btn_show('row_click');
    },
    btn_deselect() {
      this.selected_item = null;
      this.btn_show('btn_deselect');
    },
    btn_show(from) {
      var btn_add_show = false;
      var btn_upd_show = false;
      var btn_del_show = false;
      var btn_clear_show = true;

      var attr_already_exist = false;
      if (this.selectedCustomer.length > 0) {
        var form_obj = new Object();

        form_obj.hidden = this.selectedHidden;
        form_obj.customerId = this.selectedWho;
        form_obj.web_access = this.selectedWebAccess;

        form_obj.name = this.selectedName;
        form_obj.value = this.selectedValue;
        form_obj.what = this.selectedWhat;

        // GET ID
        var id = 0;
        var item_exist_id = 0;
        var selected_id = parseInt(this.selected_item?.id) || 0;
        this.all_rows.forEach((element) => {
          if (
            form_obj.hidden == element.hidden &&
            form_obj.customerId == element.customerId &&
            form_obj.name == element.name &&
            form_obj.value == element.value &&
            form_obj.what == element.what &&
            form_obj.web_access == element.web_access
          )
            id = parseInt(element.id) || 0;

          if (
            form_obj.customerId == element.customerId &&
            form_obj.name == element.name &&
            form_obj.value == element.value
          )
            item_exist_id = parseInt(element.id) || 0;
        });

        //console.log('btn_show',id,selected_id,item_exist_id)
        // SELECTED ITEM
        if (this.selected_item != null) {
          // UPDATE
          if (id == 0 && (item_exist_id == selected_id || item_exist_id == 0)) {
            btn_upd_show = true;
          }
          // DELETE
          else if (id == selected_id) btn_del_show = true;
          // ALREADY EXIST
          else if (id == 0 && item_exist_id > 0 && item_exist_id != selected_id)
            attr_already_exist = true;
        } else {
          if (this.selectedName.length > 0) {
            if (item_exist_id == 0) btn_add_show = true;
            else attr_already_exist = true;
          }
        }
      }

      this.btn_add_show = btn_add_show;
      this.btn_upd_show = btn_upd_show;
      this.btn_del_show = btn_del_show;
      this.btn_clear_show = btn_clear_show;
      //console.log(from,id,selected_id,this.selected_item)
      this.dummy=from;

      if (btn_del_show) this.rubrik = "Radera attribut";
      else if (btn_add_show) this.rubrik = "Lägg till attribut";
      else if (btn_upd_show) this.rubrik = "Uppdatera attribut";
      else if (attr_already_exist) this.rubrik = "Attributet finns redan";
      else this.rubrik = "Attribut";
    },
    btn_add_attributes() {
      var item = new Object();
      var org_key =
        parseInt(this.get_store("company.access[0].orgs[0].org_key")) || 0;
      if (org_key > 0) {
        var obj = this.get_object_access("update");
        obj.data.org_key = org_key.toString();
        if (
          this.selectedWho &&
          this.selectedWho != "" &&
          this.selectedWho != "Alla"
        )
          obj.data.customerId = this.selectedWho;
          else
          obj.data.customerId='';

        // ENDAST ORG KEY = 1330 - TA BORT VID SKARPT- borttaget
        if (org_key > 0) {

          // ADD KATEGORY ITEMS
          if(this.catergory_selecteded)
          {
              var settings_rows=this.get_settings_row_category();

              item=new Object();
              item.hidden = this.selectedHidden;
               item.web_access = this.selectedWebAccess;

              settings_rows.forEach(element => {
                item.name=element.name;
                item.value=element.value;
                item.what=element.what;
                if(this.all_rows.filter(v => v.customerId==obj.data.customerId && v.name==item.name && v.value== item.value).length==0)
                {
                  obj.data.item=item;
                  this.get_ip_post(obj,false).then((response)=>
                  {
                    this.dummy=response;
                  })
                }
              });
              // UPDATE
              this.get_org(org_key).then((response) => {
                if (response && response != null) {
                  this.upd_all_rows(response).then((answer) => {
                    if (answer == 1) {
                      this.btn_show('btn_add_attributes');
                    }
                  });
                }
              });

              console.log(settings_rows,this.all_rows,this.selectedWho)
          }
          // ADD SINGLE ATTRIBUTE
          else
          {
          item = new Object();
          item.name = this.selectedName;
          item.value = this.selectedValue;
          item.what = this.selectedWhat;
          item.hidden = this.selectedHidden;
          item.web_access = this.selectedWebAccess;
          obj.data.item = item;

          this.get_ip_post(obj, true).then((response) => {
            if (response && response != null) {
              this.get_org(org_key).then((response) => {
                if (response && response != null) {
                  this.upd_all_rows(response).then((answer) => {
                    if (answer == 1) {
                      this.btn_show('btn_add_attributes');
                    }
                  });
                }
              });
            }
          });
          }
          
        }
      }
    },
    btn_upd_attributes() {
      var org_key =
        parseInt(this.get_store("company.access[0].orgs[0].org_key")) || 0;
      var id = parseInt(this.selected_item?.id) || 0;
      if (org_key > 0 && id > 0) {
        var obj = this.get_object_access("update");
        obj.data.org_key = org_key.toString();
        if (
          this.selectedWho &&
          this.selectedWho != "" &&
          this.selectedWho != "Alla"
        )
          obj.data.customerId = this.selectedWho;

        // ENDAST ORG KEY = 1330 - TA BORT VID SKARPT -borttaget
        if (org_key >0) {
          var item = new Object();
          item.id = id.toString();
          item.name = this.selectedName;
          item.value = this.selectedValue;
          item.what = this.selectedWhat;
          item.hidden = this.selectedHidden;
          item.web_access = this.selectedWebAccess;
          obj.data.item = item;

          this.get_ip_post(obj, true).then((response) => {
            if (response && response != null) {
              this.get_org(org_key).then((response) => {
                if (response && response != null) {
                  this.upd_all_rows(response).then((answer) => {
                    if (answer == 1) {
                      this.btn_show('btn_upd_attributes');
                    }
                  });
                }
              });
            }
          });
        }
      }
    },
    btn_del_attributes() {
      var org_key =
        parseInt(this.get_store("company.access[0].orgs[0].org_key")) || 0;
      var id = parseInt(this.selected_item?.id) || 0;
      if (org_key > 0 && id > 0) {
        var obj = this.get_object_access("delete");
        obj.data.org_key = org_key.toString();
        var item = new Object();
        item.id = id.toString();
        obj.data.item = item;
        obj.data.id = id.toString();

        // ENDAST ORG KEY = 1330 - TA BORT VID SKARPT
        if (obj.data.org_key == "1330") {
          this.get_ip_post(obj, true).then((response) => {
            if (response && response != null) {
              this.get_org(org_key).then((response) => {
                if (response && response != null) {
                  this.upd_all_rows(response).then((answer) => {
                    if (answer == 1) {
                      this.clear_attribute();
                      this.btn_show('btn_del_attributes');
                    }
                  });
                }
              });
            }
          });
        }
      }
    },

    get_customer(org_key, customerId) {
      this.get_org(org_key).then((response) => {
        if (response && response != null) {
          this.upd_who(response).then((answer) => {
            if (answer == 1) {
              this.upd_all_rows(response).then((answer) => {
                if (answer == 1) {
                  this.get_customer_attributes(customerId);
                  this.show_customer = true;
                }
              });
            }
          });
        }
      });
    },
    async get_org(org_key) {
      if (org_key > 0) {
        var obj = this.get_object_access("organization");
        obj.data.org_key = org_key.toString();

        return await this.get_ip_post(obj, true).then((response) => {
          if (response && response != null) {
            return response;
          }
        });
      }
    },
    async upd_who(response) {
      var customers = get(response, "access[0].orgs[0].customers", []);
      var offices = new Array();
      var who_lst = new Array();
      var item = new Object();
      item.text = "Inget filter";
      item.value = "no_filter";
      offices.push(item);
      item = new Object();
      item.text = "Gäller alla kontor";
      item.value = "";
      offices.push(item);
      who_lst.push(item);

      // CUSTOMERS
      if (customers && customers.length > 0) {
        customers.forEach((customer) => {
          item = new Object();
          item.text = customer.name;
          item.value = customer.customerId;
          offices.push(item);
          who_lst.push(item);
        });
      }

      this.offices = offices;
      this.who_lst = who_lst;
      return 1;
    },
    async upd_all_rows(response) {
      var only_org_items = get(response, "access[0].orgs[0].items", []);
      var customers = get(response, "access[0].orgs[0].customers", []);
      var rows = new Array();
      // ONLY ORG
      if (only_org_items && only_org_items.length > 0) {
        only_org_items.forEach((item) => {
          var row = new Object();
          row.who = "Alla";
          row.customerId = "";
          row.id = item.id;
          row.hidden = item.hidden;
          row.name = item.name;
          row.value = item.value;
          row.what = item.what;
          row.web_access = item.web_access;
          rows.push(row);
        });
      }

      // CUSTOMERS
      if (customers && customers.length > 0) {
        customers.forEach((customer) => {
          if (customer.items && customer.items.length > 0) {
            customer.items.forEach((item) => {
              var row = new Object();
              row.who = customer.name;
              row.customerId = customer.customerId;
              row.id = item.id;
              row.hidden = item.hidden;
              row.name = item.name;
              row.value = item.value;
              row.what = item.what;
              row.web_access = item.web_access;
              rows.push(row);
            });
          }
        });
      }
      rows.sort(
        (a, b) =>
          a.who.localeCompare(b.who) ||
          a.name.localeCompare(b.name) ||
          a.value.localeCompare(b.value) ||
          a.what.localeCompare(b.what)
      );
      this.all_rows = rows;

      var obj = new Object();
      obj.name = "attributes_list";
      obj.data = rows;
      this.commit_obj(obj);
      this.get_customer_attributes(this.selectedFilter);

      return 1;
    },
    get_customer_attributes(filter) {
      var answer = new Array();
      if (filter && filter == "no_filter") answer = this.all_rows;
      else if (filter == "") {
        answer = this.all_rows.filter((item) => {
          return item.customerId.length == 0;
        });
      } else {
        answer = this.all_rows.filter((item) => {
          return item.customerId.match(filter);
        });
      }

      if (
        this.chk_box_filter_name &&
        this.selectedName &&
        this.selectedName.length > 0 &&
        answer.length > 0
      ) {
        answer = answer.filter((item) => {
          return item.name == this.selectedName;
        });
      }
      this.rows = answer;
    },
  },
};
</script>
<style>
.v-data-table.row-height-50 td {
  height: 50px !important;
  max-width: 100px !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  word-break: break-all !important;
  overflow: hidden !important;
}
/* 
label {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 18px;
} */

label {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 17px;
}

button {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 14px;
}

/* .verdana {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 17px;
} */
</style>
