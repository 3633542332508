import {
  ATTRIBUTES_LIST,
  COMPANY,
  COMPANY_LIST,
  SESSION,
  SET_ANSWER,
  VERIFY_TOKEN_V3,
} from './types.js';

export default {
  async commit_answer({ commit }, obj) {
    commit(SET_ANSWER, obj);
  },

  async set_session({ commit }, payload) {
    commit(SESSION, payload);
  },

  async reset_state_on_logout({ commit }) {
    commit(VERIFY_TOKEN_V3, null);
    commit(SESSION, null);
    commit(COMPANY_LIST, null);
    commit(COMPANY, null);
    commit(ATTRIBUTES_LIST, null);
  },
};
