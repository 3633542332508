import Vue from 'vue'
import App from './App.vue'
import 'es6-promise/auto'
import storeInit from './store';
import vuetify from './plugins/vuetify'

import VueCookies from 'vue-cookies';
import router from './router'
Vue.use(VueCookies);
Vue.$cookies.config('30d'); // 30 dagars expireTime på cookies default


Vue.config.productionTip = false
const store = storeInit();

new Vue({
  store,
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
